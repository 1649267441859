import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import AppInstallCtaHomepage from '../AppInstallCta/AppInstallCtaHomepage';
import market from 'images/icons/channels/market.svg';
import blog from 'images/icons/channels/blog.svg';
import tv from 'images/icons/channels/tv.svg';
import select from 'images/icons/channels/select.svg';
import portal from 'images/icons/channels/vip.svg';

export const ENTRIES = {
  blog: {
    text: '生活誌',
    image: blog
  },
  market: {
    text: '市集',
    image: market
  },
  tv: {
    text: '影音',
    image: tv
  },
  select: {
    text: '試用推薦',
    image: select
  },
  portal: {
    text: '獨享專區',
    image: portal
  }
};

const HomepageChannelEntries = ({ type, isVip, channelEntries }) => {
  let channels = ['portal', 'blog', 'market', 'tv', 'select'];

  if (!isVip) channels = channels.filter((channel) => channel !== 'portal');

  return (
    <HomepageSection className={styles.homepageChannelEntries}>
      <nav
        className={styles.homepageChannelEntriesNavigation}
        data-with-landing={!isVip}
      >
        {channels.map((channel, index) => (
          <a
            className={styles.homepageChannelEntriesNavigationItem}
            key={channel}
            href={channelEntries[channel]}
            data-track-id="homepage"
            data-module="固定入口"
            data-type={type}
            data-position={index + 1}
          >
            <figure
              className={styles.homepageChannelEntriesNavigationItemImage}
            >
              <img
                className={
                  styles.homepageChannelEntriesNavigationItemImageSource
                }
                src={ENTRIES[channel].image}
                alt={`${ENTRIES[channel].text} icon`}
              />
              <figcaption
                className={
                  styles.homepageChannelEntriesNavigationItemImageCaption
                }
              >
                {ENTRIES[channel].text}
              </figcaption>
            </figure>
          </a>
        ))}
      </nav>
      <AppInstallCtaHomepage />
    </HomepageSection>
  );
};

HomepageChannelEntries.propTypes = {
  type: PropTypes.string.isRequired,
  isVip: PropTypes.bool.isRequired,
  channelEntries: PropTypes.shape({
    market: PropTypes.string.isRequired,
    blog: PropTypes.string.isRequired,
    tv: PropTypes.string.isRequired,
    select: PropTypes.string.isRequired,
    portal: PropTypes.string.isRequired,
    landing: PropTypes.string.isRequired
  }).isRequired
};

export default HomepageChannelEntries;
