import styles from './style.module.scss';
import Image from '@polydice/icook-image-helper';
import HomepageSection from './HomepageSection';
import AppStoreImage from 'images/getapp/app-store-2.svg';
import GooglePlayImage from 'images/getapp/google-play-2.svg';

const APP = [
  {
    link: 'https://apps.apple.com/app/apple-store/id554065086?pt=498315&ct=appinstall-v2-fp&mt=8',
    title: '前往 App Store 下載愛料理 App(iOS)',
    image: AppStoreImage,
    platform: 'ios'
  },
  {
    link: 'https://play.google.com/store/apps/details?id=com.polydice.icook&referrer=utm_source%3Dicook-web%26utm_medium%3Dfpv2%26utm_content%3Drating%26utm_campaign%3Dappinstall-v2',
    title: '前往 Google Play 下載愛料理 App(Android)',
    image: GooglePlayImage,
    platform: 'android'
  }
];

const HomepageAppDownload = () => {
  return (
    <HomepageSection
      title="愛料理 App 看食譜做菜螢幕不變暗"
      className={styles.homepageAppDownload}
    >
      <div className={styles.homepageAppDownloadItems}>
        {APP.map(({ platform, link, title, image }) => (
          <a
            key={platform}
            className={styles.homepageAppDownloadItem}
            href={link}
            title={title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              className={styles.homepageAppDownloadItemImage}
              src={image}
              alt={platform}
            />
          </a>
        ))}
      </div>
    </HomepageSection>
  );
};

export default HomepageAppDownload;
