import styles from './style.module.scss';
import Image from '@polydice/icook-image-helper';
import EditorsChoiceImg from 'images/editors_choice.png';
import StarImg from 'images/product/star.svg';
import GirlImg from 'images/app_install_home_girl.png';
import DownloadIcon from 'images/icons/open-new-window-web-white.svg';

const AppInstallCtaHomepage = () => {
  return (
    <a
      href="https://icook.onelink.me/Dl1H/ektrtsy7"
      className={styles.appInstallCtaHomepage}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.appInstallCtaHomepageMain}>
        <div className={styles.appInstallCtaHomepageLeft}>
          <div className={styles.appInstallCtaHomepageLeftRank}>
            <Image
              src={StarImg}
              alt="star"
              className={styles.appInstallCtaHomepageLeftRankStar}
            />
            <Image
              src={StarImg}
              alt="star"
              className={styles.appInstallCtaHomepageLeftRankStar}
            />
            <Image
              src={StarImg}
              alt="star"
              className={styles.appInstallCtaHomepageLeftRankStar}
            />
            <Image
              src={StarImg}
              alt="star"
              className={styles.appInstallCtaHomepageLeftRankStar}
            />
            <Image
              src={StarImg}
              alt="star"
              className={styles.appInstallCtaHomepageLeftRankStar}
            />
            <span>4.8</span>
          </div>
          <div className={styles.appInstallCtaHomepageLeftTexts}>
            <span className={styles.appInstallCtaHomepageLeftTextsTitle}>
              使用愛料理 APP
            </span>
            <span className={styles.appInstallCtaHomepageLeftTextsTitle}>
              享受更流暢的操作體驗
            </span>
            <span className={styles.appInstallCtaHomepageLeftTextsSubtitle}>
              榮獲多次 Apple 推薦、
            </span>
            <span className={styles.appInstallCtaHomepageLeftTextsSubtitle}>
              Google Play 編輯精選
            </span>
          </div>
          <Image
            src={EditorsChoiceImg}
            alt="editors_choice"
            className={styles.appInstallCtaHomepageLeftEditorsChoice}
          />
        </div>
        <div className={styles.appInstallCtaHomepageRight}>
          <Image
            src={GirlImg}
            alt="girl"
            className={styles.appInstallCtaHomepageRightImg}
          />
        </div>
      </div>
      <div className={styles.appInstallCtaHomepageButton}>
        <Image src={DownloadIcon} alt="download" />
        <span>免費下載 APP</span>
      </div>
    </a>
  );
};

export default AppInstallCtaHomepage;
